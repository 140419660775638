import React, { useState } from 'react';
import './App.css';
import Background from './Background';

function App() {
  const [copied, setCopied] = useState(false);
  const [isFastScroll, setIsFastScroll] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText('0x026ca25c9dc69fc3ed4c16888a8c4b686d5f5d8a');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleMouseEnter = () => {
    setIsFastScroll(true);
  };

  const handleMouseLeave = () => {
    setIsFastScroll(false);
  };

  return (
    <div className="App">
      <Background isFastScroll={isFastScroll} />
      <div className="button-container">
        <a
          href="https://app.thruster.finance/"
          target="_blank"
          rel="noopener noreferrer"
          className="button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Buy PNDA on Thruster 🐼
        </a>
        <button
          className={`button ${copied ? 'copied' : ''}`}
          onClick={copyToClipboard}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {copied ? 'Copied!' : 'Copy Address'}
        </button>
      </div>
    </div>
  );
}

export default App;