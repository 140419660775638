import React from 'react';
import './Background.css';

interface BackgroundProps {
  isFastScroll: boolean;
}

const Background: React.FC<BackgroundProps> = ({ isFastScroll }) => {
  return <div className={`background ${isFastScroll ? 'fast-scroll' : ''}`}></div>;
};

export default Background;